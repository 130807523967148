
import { defineComponent, ref, onMounted } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { ApiBase } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";

interface Filter {
  platform_id: string | number;
  refund_order_no: string;
  payment_order_no: string;
  status: string;
  refund_time_start: string;
  refund_time_end: string;
}

export default defineComponent({
  name: "refund-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<Filter>({
      platform_id: "",
      refund_order_no: "",
      payment_order_no: "",
      status: "",
      refund_time_start: "",
      refund_time_end: "",
    });

    const customer = ref<TaggingItem>({
      label: "",
      value: "",
    });

    const { t } = useI18n();

    const options = ref({
      platform: [] as TaggingItem[],
      customer: [] as TaggingItem[],
      customerLoading: false,
      status: [
        {
          label: t("payment.pending"),
          value: "pending",
        },
        {
          label: t("payment.refunded"),
          value: "refunded",
        },
        {
          label: t("payment.canceled"),
          value: "canceled",
        },
        {
          label: t("payment.failed"),
          value: "failed",
        },
      ] as TaggingItem[],
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["payment_platform"],
      });
      if (data.code == 0) {
        options.value.platform = data.data.payment_platform.items;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
    });

    return {
      formData,
      formRef,
      customer,
      options,
      t,
      submit,
      handleReset,
    };
  },
});
