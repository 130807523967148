import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }
const _hoisted_12 = {
  key: 0,
  class: "fs-7 text-gray-400"
}
const _hoisted_13 = { class: "mw-125px" }
const _hoisted_14 = { class: "mw-200px" }
const _hoisted_15 = { class: "text-nowrap" }
const _hoisted_16 = { class: "text-truncate fs-7 text-gray-400" }
const _hoisted_17 = { class: "d-flex flex-row flex-nowrap justify-content-end align-items-center" }
const _hoisted_18 = { class: "svg-icon svg-icon-3" }
const _hoisted_19 = ["title", "onClick"]
const _hoisted_20 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_FilterDropdown = _resolveComponent("FilterDropdown")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-14",
            placeholder: _ctx.t('common.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ]),
          _withDirectives(_createElementVNode("span", {
            class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
          }, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
          ], 512), [
            [_vShow, _ctx.search != '']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
            ]),
            _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
          ]),
          _createVNode(_component_FilterDropdown, {
            onSubmitFilter: _ctx.handleFilter,
            onResetFilter: _ctx.handleFilterReset
          }, null, 8, ["onSubmitFilter", "onResetFilter"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        order: "desc",
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-refund_order_no": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.refund_order_no) + " ", 1),
          (item.sales_order_no)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(item.sales_order_no), 1))
            : _createCommentVNode("", true)
        ]),
        "cell-created_at": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDateTime(item.created_at)), 1)
        ]),
        "cell-refund_time": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(_ctx.formatDateTime(item.refund_time)), 1)
        ]),
        "cell-customer": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_13, _toDisplayString(item.customer_name), 1)
        ]),
        "cell-reason": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_14, _toDisplayString(item.reason), 1)
        ]),
        "cell-amount": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$currency.currency(item.amount, 2, ",", ".", "‌€ ")), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(item.__show.platform), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          _createElementVNode("span", {
            class: _normalizeClass(["badge", _ctx.getStatusClass(item.status)])
          }, _toDisplayString(item.__show.status), 3)
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_router_link, {
              to: '/payment-management/refund/' + item.id + '/overview',
              class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary me-3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_18, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen004.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"]),
            (item.status === _ctx.RefundStatus.Failed)
              ? _withDirectives((_openBlock(), _createElementBlock("button", {
                  key: 0,
                  type: "button",
                  class: "btn btn-sm btn-icon btn-light-danger",
                  title: _ctx.t('common.cancel'),
                  onClick: ($event: any) => (_ctx.onCancelButtonClick(item.id))
                }, [
                  _createElementVNode("span", _hoisted_20, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                  ])
                ], 8, _hoisted_19)), [
                  [_directive_auth, { auth: ['update'] }]
                ])
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ])
  ]))
}